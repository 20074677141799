<template>
  <div class="assignment flex flex-center">
    <div class="assignmentc">
      <div class="assignment-right-master">
        <img
          :src="this.partyList[this.partyIndex].imgPc"
          alt=""
          class="assignment-right-master-img"
        />
        <div class="assignment-right-master-gg" @click="dailyattendanceww()">
          <img
            src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/Group9863.png"
            alt=""
            class="assignment-right-master-gg-img"
          />
        </div>
      </div>
      <div
        v-for="(item, index) in partyList1"
        :key="index"
        class="assignment-right-task flex items-center flex-between"
      >
        <div>
          <div class="assignment-right-task-name" @click="buttonUrl(item)">
            {{ item.taskName }}
          </div>
          <div class="flex assignment-right-relations items-center">
            <img
              src="https://cdn.bubbleplan.cn/static/textImg/Vector112x.png"
              alt=""
              class="assignment-right-relations-img"
            />
            <div class="assignment-right-relations-num">{{ item.award }}人</div>
            <div class="assignment-right-relations-border"></div>
            <div class="assignment-right-relations-time">
              截至{{ dateTime() }}
            </div>
          </div>
        </div>
        <div>
          <div class="flex items-center">
            <div class="flex items-center">
              <img
                src="https://cdn.bubbleplan.cn/static/home/Group9266.png"
                alt=""
                class="assignment-right-task-img"
              />
              <div class="assignment-right-task-numb">×1</div>
            </div>
            <div
              :class="
                item.submitStatus == -1 || item.submitStatus == 0
                  ? 'list-bac-content-task-award-button'
                  : item.submitStatus == 2
                  ? 'list-bac-content-task-award-button2'
                  : 'list-bac-content-task-award-button1'
              "
              @click="uploadTask(item)"
            >
              {{
                item.submitStatus == -1
                  ? "上传弹窗"
                  : item.submitStatus == 0
                  ? "审核中"
                  : item.submitStatus == 1
                  ? "通过"
                  : "驳回重新上传"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="assignment-right">
        <div class="assignment-right-task flex items-center flex-between">
          <div class="assignment-right-task-name">每日签到</div>
          <div class="flex items-center">
            <div class="flex items-center">
              <img
                src="https://cdn.bubbleplan.cn/static/home/Group9266.png"
                alt=""
                class="assignment-right-task-img"
              />
              <div class="assignment-right-task-numb">×1</div>
            </div>
            <div
              @click="signIn()"
              :class="
                !isSignin
                  ? 'page-list-bac-content-task-award-button1'
                  : 'page-list-bac-content-task-award-button'
              "
            >
              {{ isSignin ? "已签到" : "签到" }}
            </div>
          </div>
        </div>
      </div>
      <div style="height: 50px"></div>
    </div>

    <el-dialog title="上传" :visible.sync="uploadingShow" width="30%">
      <div class="uploadTask">
        <div class="uploadTask-title">确认上传图片</div>
        <div class="uploadTask-upload">
          <el-upload
            action="https://server.bubbleplan.cn/api/v1.0/common/fileUpload"
            :headers="{
              token: username.token,
            }"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
          >
            <img v-if="taskImg" :src="taskImg" class="avatar" />
            <i v-else class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="uploadTask-button flex flex-evenly">
          <div class="uploadTask-button-left" @click="close()">取消</div>
          <div class="uploadTask-button-right" @click="submitTask()">确认</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="积分榜单" :visible.sync="viewListShow" width="25%">
      <div class="viewListList">
        <div class="flex">
          <div
            style="
              width: 50%;
              color: #ffffff;
              font-size: 20px;
              text-align: center;
            "
          >
            昵称
          </div>
          <div
            style="
              width: 50%;
              color: #ffffff;
              font-size: 20px;
              text-align: center;
            "
          >
            积分
          </div>
        </div>
        <div class="viewListList-ul">
          <div
            v-for="(item, index) in rankListData"
            :key="index"
            class="viewListList-ul-li flex"
          >
            <div class="viewListList-ul-li-div">
              {{ item.name }}
            </div>
            <div class="viewListList-ul-li-div">
              {{ item.integration }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      partyList: [],
      numberofpeople: 0,
      partyIndex: 0,
      uploadingShow: false,
      taskImg: "",
      viewListShow: false,
      rankListData: [],
      partyList1: [],
      isSignin: null,
      partyData: {},
    };
  },
  mounted() {
    this.listTask();
    this.isSign();
  },
  methods: {
    buttonUrl(item) {
      window.open(item.url);
    },

    async signIn() {
      const { data, code } = await this.$ajax.signIn();
      if (code == 200) {
        this.isSign();
      }
    },

    //获取签到
    async isSign() {
      const { data, code } = await this.$ajax.isSign();
      this.isSignin = data;
    },
    dailyattendanceww() {
      console.log(22222222222);
      this.$router.push("/dailyattendance/dailyattendance");
    },

    dailyattendance() {
      this.partyIndex = 100;
    },

    standingsSheet() {
      this.rankList();
      this.viewListShow = true;
    },

    //获取积分排行榜
    async rankList() {
      const { data, code } = await this.$ajax.rankList();
      this.rankListData = data;
    },
    uploadTask(item) {
      if (item.submitStatus == -1 || item.submitStatus == 2) {
        this.partyData = item;
        this.uploadingShow = true;
      }
    },
    //提交任务
    async submitTask() {
      console.log(this.taskImg);
      if (!this.taskImg) {
        return;
      }
      const params = {
        taskId: this.partyData.id,
        img: this.taskImg,
      };
      const { data, code } = await this.$ajax.submitTask(params);
      if (code == 200) {
        this.partyList1 = [];
        this.uploadingShow = false;
        this.taskImg = null;
        this.listTask();
      }
    },
    close() {
      this.uploadingShow = false;
      this.taskImg = null;
    },
    handleAvatarSuccess(res, file) {
      this.taskImg = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type == "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG或PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    dateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const hour = now.getHours();
      const minute = now.getMinutes();
      const second = now.getSeconds();
      return (
        year +
        "/" +
        month +
        "/" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    //查询人数
    async numberOfParticipants(index) {
      const { data, code } = await this.$ajax.numberOfParticipants({
        taskId: this.partyList[index].id,
      });
      if (code == 200) {
        this.partyList[index] = {
          ...this.partyList[index],
          award: data,
        };
        this.partyList1.push({
          ...this.partyList[index],
          award: data,
        });
      }
    },

    //获取列表
    async listTask() {
      const { data, code } = await this.$ajax.listTask();
      this.partyList = data;

      for (var i = 0; i < this.partyList.length; i++) {
        this.numberOfParticipants(i);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.assignment {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/home/gamedownload_bgc.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 60px;
  overflow: auto;

  &-type {
    margin-top: 53px;
    margin-right: 80.88px;
    &-li {
      width: 206.12px;
      height: 57.93px;
      line-height: 57.93px;
      text-align: center;
      background: url("https://cdn.bubbleplan.cn/static/home/Group9609.png")
        no-repeat;
      background-size: 100% 100%;
      color: #babfa6;
      font-size: 23px;
      font-weight: 700;
      margin-bottom: 29.7px;
    }
    .type_index {
      width: 206.12px;
      height: 57.93px;
      line-height: 57.93px;
      text-align: center;
      background: url("https://cdn.bubbleplan.cn/static/home/Group9608.png")
        no-repeat;
      background-size: 100% 100%;
      color: #000000;
      font-size: 23px;
      font-weight: 700;
      margin-bottom: 29.7px;
    }
  }
  &-right {
    &-master {
      position: relative;
      &-img {
        width: 1085px;
        height: 301px;
        position: relative;
        z-index: 99;
      }
      &-text:hover {
        cursor: pointer;
      }
      &-text {
        position: absolute;
        top: 22px;
        right: 30px;

        &-name {
          color: #ffffff;
          font-size: 21px;
          margin-right: 13px;
        }
        &-img {
          width: 22px;
          height: 22px;
        }
      }
      &-gg:hover {
        cursor: pointer;
      }
      &-gg {
        position: absolute;
        right: -140px;
        bottom: -10px;
        &-img:hover {
          cursor: pointer;
        }
        &-img {
          width: 194px;
          height: 140.33px;
        }
      }
    }
    &-relations {
      margin-top: 23px;
      &-img {
        width: 22px;
        height: 21px;
      }
      &-num {
        color: #ffffff80;
        font-size: 22px;
        margin-left: 10px;
        margin-right: 26px;
      }
      &-border {
        width: 1px;
        height: 27px;
        background: #ffffff80;
        margin-right: 25px;
      }
      &-time {
        color: #ffffff80;
        font-size: 22px;
      }
    }
    &-task {
      margin-top: 30px;
      width: 1085px;
      height: 141px;
      background: url("https://cdn.bubbleplan.cn/static/home/Group9610.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 32px 40px;
      &-name {
        color: #ffffff;
        font-size: 22px;
      }
      &-name:hover {
        cursor: pointer;
      }
      &-img {
        width: 80px;
        height: 80px;
      }
      &-numb {
        color: #10061d;
        font-size: 22px;
        font-weight: 900;
        text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
          1px 1px 0 #fff;
        /* 模拟描边效果 */
        margin-top: 51px;
        margin-left: -20px;
        margin-right: 89px;
      }
      &-img2 {
        width: 245.32px;
        height: 81.56px;
      }
      &-img2:hover {
        cursor: pointer;
      }
    }
  }
  :deep(.el-dialog__title) {
    color: #daff00;
    font-size: 24px;
    font-weight: 400;
  }

  :deep(.el-dialog) {
    border-radius: 20px;
    background: #13212f;
  }

  :deep(.el-dialog__close el-icon el-icon-close) {
    color: #64849a;
    font-size: 20px;
  }

  :deep(.el-dialog__header) {
    border-bottom: 2px solid #021521;
  }
  .uploadTask {
    &-title {
      color: #ffffff;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
    &-upload {
      margin-top: 30px;
    }
    .avatar {
      width: 140px;
      height: 140px;
      margin: auto;
    }
    &-button {
      margin-top: 76px;
      padding: 0 30px;
      &-left:hover {
        cursor: pointer;
      }
      &-right:hover {
        cursor: pointer;
      }
      &-left {
        width: 178px;
        height: 62px;
        color: #cfcfd3;
        font-size: 22px;
        text-align: center;
        line-height: 62px;
        background: url("https://cdn.bubbleplan.cn/static/home/Group3377.png")
          no-repeat;
        background-size: 100% 100%;
      }
      &-right {
        width: 178px;
        height: 62px;
        color: #000000;
        font-size: 22px;
        text-align: center;
        line-height: 62px;
        background: url("https://cdn.bubbleplan.cn/static/home/Group3378.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
    :deep(.el-upload) {
      width: 100%;
    }
    :deep(.el-icon-plus) {
      width: 140px;
      height: 140px;
      background: url("https://cdn.bubbleplan.cn/static/img/img_add.png")
        no-repeat;
      background-size: 100% 100%;
      border: 0 dashed #c0ccda;
      //   margin-left: 36%;
    }
    :deep(.el-icon-plus) {
      font-size: 0;
    }
    :deep(.el-upload-list--picture-card .el-upload-list__item) {
      width: 140px;
      height: 140px;
    }
  }

  .viewListList {
    &-ul::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &-ul {
      margin-top: 18px;
      height: 422px;
      overflow: auto;

      &-li {
        border-top: 1px solid #25465c;
        padding: 17px;

        &-div {
          color: #64849a;
          font-size: 20px;
          width: 50%;
          text-align: center;
        }
      }
    }
  }

  .page-list-bac-content-task-award-button {
    width: 245px;
    height: 79.89px;
    background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/home/gray.png")
      no-repeat;
    background-size: 100% 100%;
    font-size: 25px;
    color: #000;
    text-align: center;
    line-height: 79.89px;
    font-weight: 700;
  }
  .page-list-bac-content-task-award-button1 {
    width: 245px;
    height: 79.89px;
    background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/home/green.png")
      no-repeat;
    background-size: 100% 100%;
    font-size: 25px;
    color: #000;
    text-align: center;
    line-height: 79.89px;
    font-weight: 700;
  }

  .list-bac-content-task-award-button:hover {
    cursor: pointer;
  }
  .list-bac-content-task-award-button {
    width: 245px;
    height: 79.89px;
    color: #000000;
    text-align: center;
    font-size: 30px;
    line-height: 80px;
    background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/home/green.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .list-bac-content-task-award-button1 {
    width: 245px;
    height: 79.89px;
    color: #000000;
    text-align: center;
    font-size: 30px;
    line-height: 80px;
    background: url("http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/home/gray.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .list-bac-content-task-award-button2:hover {
    cursor: pointer;
  }
  .list-bac-content-task-award-button2 {
    width: 245px;
    height: 79.89px;
    color: #000000;
    text-align: center;
    font-size: 30px;
    line-height: 95px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/Group9269.png")
      no-repeat;
    background-size: 100% 100%;
  }
}
</style>